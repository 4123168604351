<template>
  <div>
    <b-form @submit.stop.prevent="onSubmit">
      <Alert variant="green" v-if="updateSuccess">Successfully updated organization! </Alert>
      <Alert variant="green" v-if="deleteOrganization">Successfully deleted the organization!</Alert>
      <Alert v-if="errorMessage" variant="red"> Failed to edit organization: {{ errorMessage }} </Alert>

      <b-form-group label-for="input-name" :invalid-feedback="veeErrors.first('input-name')">
        <template slot="label">Name <span class="text-danger">*</span></template>
        <b-form-input
          name="input-name"
          v-model="editOrganization.name"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('input-name')"
          aria-describedby="input-name-feedback"
          data-vv-as="name"
          trim
        />
      </b-form-group>

      <b-form-group label-for="input-legal-name" :invalid-feedback="veeErrors.first('input-legal-name')">
        <template slot="label">Legal Name <span class="text-danger">*</span></template>
        <b-form-input
          name="input-legal-name"
          v-model="editOrganization.legalName"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('input-legal-name')"
          aria-describedby="input-legal-name-feedback"
          data-vv-as="legal name"
          trim
        />
      </b-form-group>

      <b-form-group
        label="Description"
        label-for="input-description"
        :invalid-feedback="veeErrors.first('input-description')"
      >
        <b-form-input
          name="input-description"
          v-model="editOrganization.description"
          v-validate="{ min: 3 }"
          :state="validateState('input-description')"
          data-vv-as="description"
          trim
        />
      </b-form-group>

      <b-form-group label-for="input-shortlink" :invalid-feedback="veeErrors.first('input-shortlink')">
        <template slot="label">Shortlink <span class="text-danger">*</span></template>
        <b-form-input
          name="input-shortlink"
          v-model="editOrganization.shortlink"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('input-shortlink')"
          data-vv-as="shortlink"
          trim
        />
      </b-form-group>

      <b-form-group
        label="Pixel Code"
        label-for="input-pixel-code"
        :invalid-feedback="veeErrors.first('input-pixel-code')"
      >
        <b-form-input
          name="input-pixel-code"
          v-model="editOrganization.pixelCode"
          v-validate="{ min: 3 }"
          :state="validateState('input-pixel-code')"
          data-vv-as="pixel code"
          trim
        />
      </b-form-group>
      <b-form-group
        label="Country"
        label-for="input-country"
        :invalid-feedback="veeErrors.first(`input-country`)"
        class="mb-4"
      >
        <b-form-select
          name="input-country"
          :state="validateState(`country_input`)"
          v-model="country"
          :aria-describedby="`input-country-feedback`"
          :options="countryOptions"
          v-validate="{ required: false }"
          :data-vv-as="country"
          disabled
        />
      </b-form-group>
      <b-form-group
        :label="dropdownLabel"
        :label-for="`input-${dropdownLabel}`"
        :invalid-feedback="veeErrors.first(`input-${dropdownLabel}`)"
        class="mb-4"
      >
        <b-form-select
          :name="`input-${dropdownLabel}`"
          :state="validateState(`${dropdownLabel}_input`)"
          v-model="province"
          :aria-describedby="`input-${dropdownLabel}-feedback`"
          :options="provinceOptions"
          v-validate="{ required: true }"
          :data-vv-as="dropdownLabel"
        />
      </b-form-group>
      <SalesRepDropdownInput v-model="salesRepUuid" required />

      <!-- Parent Organization -->
      <b-form-group label="Parent Organization" label-for="input-parent-organization" class="mb-4">
        <OrganizationSelectV2
          :value="parentOrganizationId"
          inputName="input-parent-organization"
          @organizationSelected="organizationSelected"
          ref="multiselect"
        />
      </b-form-group>
      <b-form-group label-for="input-partner-id" :invalid-feedback="veeErrors.first('input-partner-id')">
        <template slot="label">
          Partner ID
          <span
            style="margin-left: 0.5rem"
            v-b-tooltip.hover.top="'External ID of organization. Used by partners for identification.'"
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </template>

        <b-form-input
          name="input-partner-id"
          v-model="partnerId"
          v-validate="{ max: 255 }"
          :state="validateState('input-partner-id')"
          data-vv-as="partner ID"
          trim
        />
      </b-form-group>

      <b-form-group
        label-for="input-statement-descriptor"
        :invalid-feedback="veeErrors.first('input-statement-descriptor')"
      >
        <template slot="label"
          >Ticket Buyer Statement Descriptor
          <span
            style="margin-left: 0.5rem"
            v-b-tooltip.hover.top="'This is the text that will appear on the Ticket Buyer\'s credit card statement.'"
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </template>

        <b-form-input
          name="input-statement-descriptor"
          v-model="statementDescriptor"
          v-validate="{ min: 5, max: 22, regex: regex }"
          :state="validateState('input-statement-descriptor')"
          aria-describedby="input-statement-descriptor-feedback"
          data-vv-as="statement descriptor"
          trim
        />
      </b-form-group>

      <dl class="row mb-2">
        <dt class="col-sm-7">
          Allowed to Activate Raffles
          <span
            style="margin-left: 0.5rem"
            v-b-tooltip.hover.top="
              'If enabled then any Account Owner for this Organization can approve and activate Raffles.'
            "
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </dt>
        <dd class="col-sm-5 text-right">
          <b-form-checkbox v-model="settings.canActivateEvents" size="lg" name="can-activate-event" switch />
        </dd>
      </dl>
      <dl class="row mb-2">
        <dt class="col-sm-7">
          Sync with QuickBooks
          <span
            style="margin-left: 0.5rem"
            v-b-tooltip.hover.top="
              'If enabling this for the first time, ensure there is an existing customer in QuickBooks to represent this organization.'
            "
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </dt>
        <dd class="col-sm-5 text-right">
          <b-form-checkbox v-model="settings.quickbooksSyncEnabled" size="lg" name="quickbooks-sync-enabled" switch />
        </dd>
      </dl>
      <dl class="row mb-2">
        <dt class="col-sm-7">
          Enable Product Goldrush
          <span
            style="margin-left: 0.5rem"
            v-b-tooltip.hover.top="'If enabled then Goldrush will be enabled for this organization.'"
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </dt>
        <dd class="col-sm-5 text-right">
          <b-form-checkbox v-model="productGoldrushEnabled" size="lg" name="product-goldrush-enabled" switch />
        </dd>
      </dl>
      <dl v-if="masterEvent" class="row mb-8">
        <dt class="col-sm-7">Enable Goldrush Online</dt>
        <dd class="col-sm-5 text-right">
          <b-form-checkbox v-model="onlineEnabled" size="lg" name="enable-goldrush-online" switch />
        </dd>
      </dl>

      <dl class="row mb-2">
        <dt class="col-sm-7">Logo</dt>
      </dl>
      <b-form-group>
        <Alert v-if="uploadLogoError" variant="red" icon="exclamation" class="mr-5 my-3"
          >Error uploading image: {{ uploadLogoError }}</Alert
        >
        <ImageUploadCropper
          v-else
          :aspectRatio="{ aspectRatio: 16 / 9 }"
          :imageUrl="editOrganization.logoUrl"
          :uploadError="uploadLogoError"
          :uploadSuccess="uploadLogoSuccess"
          :maxFileSize="1000000"
          @uploadImage="uploadLogo"
          @uploadImageError="setUploadLogoError"
          @clearImage="clearLogo"
          imageName="organization-logo"
        />
      </b-form-group>
      <LoadingButton ref="loadingButton" variant="green" :loading="saving" @onClick="saveOrganization()"
        >Save</LoadingButton
      >
    </b-form>

    <div v-if="organization">
      <b-button v-b-modal="'delete-organization-' + organization.id" auto class="mt-5 border-0 bg-danger">
        Delete Organization
      </b-button>
      <DeleteOrganizationModal
        v-if="organization"
        :modalId="organization.id"
        :organizationName="organization.name"
        show
        @deleteSuccess="deleteSuccess"
      />
    </div>
  </div>
</template>
<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import GoldrushServiceV2 from '@/lib/goldrush-service-v2';
import ProvinceStateService from '@/lib/province-state-view-service';
import Alert from '@/components/ui/Alert';
import DeleteOrganizationModal from '@/components/modals/DeleteOrganizationModal';
import LoadingButton from '@/components/ui/LoadingButton';
import SalesRepDropdownInput from '@/components/inputs/SalesRepDropdownInput';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2.vue';
import ImageUploadCropper from '@/components/forms/ImageUploadCropper';
import formatUrl from '@/lib/s3';

export default {
  components: {
    Alert,
    LoadingButton,
    SalesRepDropdownInput,
    OrganizationSelectV2,
    ImageUploadCropper,
    DeleteOrganizationModal
  },
  props: {
    organization: {
      type: Object
    }
  },
  data() {
    return {
      saving: false,
      updateFailed: false,
      updateSuccess: false,
      errorMessage: null,
      province: null,
      dropdownLabel: '',
      provinceOptions: null,
      country: null,
      salesRepUuid: null,
      parentOrganizationId: this.organization?.parentOrganization?.uuid || null,
      partnerId: null,
      editOrganization: {
        name: null,
        description: null,
        shortlink: null,
        pixelCode: null,
        legalName: null,
        logoUrl: null
      },
      settings: {
        canActivateEvents: false,
        quickbooksSyncEnabled: false
      },
      masterEvent: null,
      onlineEnabled: false,
      countryOptions: [],
      uploadLogoError: null,
      uploadLogoSuccess: null,
      statementDescriptor: '',
      regex: /^(?=.*[a-zA-Z])[^<>\\'"*]+$/,
      deleteOrganization: false,
      productGoldrushEnabled: false
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  async created() {
    const customValidationMessages = {
      custom: {
        'input-statement-descriptor': {
          min: 'Must be at least 5 characters long',
          max: 'Must be at most 22 characters long',
          regex:
            'Must contain at least one letter. Cannot contain any of the following special characters: < , >, \\ , * , \' , "'
        }
      }
    };

    this.$validator.localize('en', customValidationMessages);

    const getMasterEvent = await GoldrushServiceV2.listMasterEvent({ organizationId: this.organization.id });
    this.provinceStateView = ProvinceStateService.getEditRaffleModalView(this.organization.country);
    this.provinceOptions = this.provinceStateView.dropdownOptions;
    this.countryOptions = [
      { value: 'CA', text: 'Canada' },
      { value: 'US', text: 'United States' }
    ];
    this.country = this.organization.country;
    this.dropdownLabel = this.provinceStateView.dropdownLabel;
    if (getMasterEvent) {
      this.masterEvent = getMasterEvent.data[0];
    }
    this.resetForm();
  },
  methods: {
    organizationSelected(organization) {
      this.parentOrganizationId = organization?.id ? organization.id : null;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.saving = false;
      this.updateFailed = false;
      this.updateSuccess = false;
      this.errorMessage = null;
      this.province = this.organization.province;
      this.salesRepUuid = this.organization.salesRepId;
      this.parentOrganizationId = this.organization?.parentOrganization?.uuid || null;
      this.partnerId = this.organization.partnerId;
      this.editOrganization.name = this.organization.name;
      this.editOrganization.legalName = this.organization.legalName;
      this.editOrganization.description = this.organization.description;
      this.editOrganization.shortlink = this.organization.shortlink;
      this.statementDescriptor = this.organization.statementDescriptor;
      this.editOrganization.logoUrl = this.organization.logoUrl;
      this.editOrganization.pixelCode = this.organization.pixelCode;
      this.settings.canActivateEvents =
        this.organization.settings && this.organization.settings.canActivateEvents
          ? this.organization.settings.canActivateEvents
          : false;
      this.settings.quickbooksSyncEnabled =
        this.organization.settings && this.organization.settings.quickbooksSyncEnabled
          ? this.organization.settings.quickbooksSyncEnabled
          : false;
      this.onlineEnabled = this.masterEvent?.onlineEnabled || false;
      this.deleteOrganization = false;
      this.productGoldrushEnabled = this.organization.productGoldrushEnabled;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    async uploadLogo(payload) {
      if (payload.blob && payload.blob.type !== 'image/jpeg' && payload.blob.type !== 'image/png') {
        this.uploadLogoError = 'Please upload a valid image file type (JPEG or PNG)';

        setTimeout(() => {
          this.uploadLogoError = null;
        }, 5000);

        return;
      }

      const file = new File([payload.blob], payload.name);

      try {
        const params = {
          organizationId: this.organization.id,
          fileName: payload.name
        };

        const logoUrl = await OrganizationServiceV2.getLogoUploadUrl(params);
        await OrganizationServiceV2.uploadFileToS3(logoUrl, file);

        this.logoUrl = formatUrl(logoUrl);
        this.editOrganization.logoUrl = this.logoUrl;

        this.uploadLogoSuccess = true;
        this.uploadLogoError = null;
        this.errorMessage = null;
      } catch (error) {
        this.uploadLogoError = error;
        this.errorMessage = 'Could not upload logo';
      }
    },

    setUploadLogoError(error) {
      this.uploadLogoError = error?.message;
    },

    clearLogo() {
      this.editOrganization.logoUrl = null;
      this.uploadLogoSuccess = null;
      this.uploadLogoError = null;
    },

    async saveOrganization() {
      try {
        this.saving = true;
        this.updateSuccess = false;
        this.updateFailed = false;

        const valid = await this.$validator.validateAll();

        if (!valid) {
          return;
        }

        const data = {
          name: this.editOrganization.name,
          description: this.editOrganization.description,
          shortlink: this.editOrganization.shortlink,
          logo: this.editOrganization.logoUrl,
          pixelCode: this.editOrganization.pixelCode,
          settings: this.settings,
          province: this.province,
          legalName: this.editOrganization.legalName,
          salesRepId: this.salesRepUuid,
          parentOrganizationId: this.parentOrganizationId,
          partnerId: this.partnerId,
          statementDescriptor: this.statementDescriptor,
          productGoldrushEnabled: this.productGoldrushEnabled
        };
        await OrganizationServiceV2.updateOrganization(this.organization.id, data);

        if (this.masterEvent) {
          await GoldrushServiceV2.updateMasterEvent(this.masterEvent.id, {
            onlineEnabled: this.onlineEnabled
          });
        }

        this.updateSuccess = true;

        this.$emit('onEdited', data);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.saving = false;
      }
    },

    deleteSuccess() {
      this.deleteOrganization = true;

      setTimeout(() => {
        this.$router.push({ name: 'Admin' });
      }, 3000);
    }
  }
};
</script>
